




































































































































































































import { Component } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Student from '@/store/entities/students/student';
import Enrollment from '@/store/entities/courses/enrollment';

@Component({
    components: { FormBase },
    watch: {
        e1: function (val) {
            if (val == 3) {
                (this as any).getAmount();
            }
        }
    }
})
export default class PublicForm extends ListBase {
    name: string = 'publicForm';
    urlPhoto: string = url;
    selectedCourse: any = null;
    document: string = '';
    document_nro: string = '';
    document_data: string = null;
    valid: boolean = false;
    validDNI: boolean = false;  
    fRules: any = {
        required: value => !!value || this.L('Debe Ingresar su numero de documento'),
        requiredFile: value => !!value || this.L('Debe Adjuntar su documento'),
        counter: value => value.length <= 8 || 'Max 8 numeros'
    };
    enrollment: Enrollment = new Enrollment();
    student: Student = new Student();
    formSent: boolean = false;
    validStudent: boolean = false;
    studentSaved: boolean = false;
    notes: string = '';
    amount: number = 0;
    e1: any = 1;

    items: Array<any> = [ 
        this.gItem({ type: 'radio', prop: 'type_id', label: 'Tipo de Asistente', generalType: 'T_STUDENT', required: true, size: 12, filterParam: 'value', filterVal: [1, 3], row: true }),
        this.gItem({ type: 'object', prop: 'document_type_id', label: 'Tipo de Documento', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Documento', required: true, size: 4 }),
        //this.gItem({ type: 'object', prop: 'gender_id', label: 'Genero', size: 3 }),
        this.gItem({ type: 'date', prop: 'birthdate', label: 'Fecha de Nacimiento', size: 4 }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'lastname', label: 'Apellidos', required: true, size: 6 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Numero de Telefono', size: 6 }),
    ];


    /***** COMPUTED METHODS *****/
    get list() {
        return this.$store.state.courseclass.list;
    }

    get totalCount() {
        return this.$store.state.courseclass.list.length;
    }

    get loading() {
        return this.$store.state.enrollment.loading;
    }

    get loadingClass() {
        return this.$store.state.courseclass.loading;
    }

    get loadingStudent() {
        return this.$store.state.student.loading;
    }

    get courseclass() {
        return this.$store.state.courseclass.list[this.selectedCourse];
    }
    
    get days() {
        let list = [];
        let week = this.gCommonList('week_days');
        this.courseclass['schedule'].days_list.forEach(id => {
            list.push(week[id-1].name);
        });
        return list;
    }

    get serie() {
        return Util.abp.setting.get('INVOICE_SERIE');
    }

    /***** EVENTS *****/
    async checkDocument(e) {
        e.preventDefault();

        if ((this.$refs.validform as any).validate()) {
            this.studentSaved = false;
            this.setItem(this.items, 'type_id', 'disabled', false);
            if (this.document == null) return;
            if (this.document.length < 8) return;
            let types = [1, 3];

            let model = await this.$store.dispatch({
                type: 'student/getByDocument',
                data: {
                    document: this.document,
                    course_class_id: this.courseclass.id
                }
            });
            this.student = Util.extend(true, {}, model);

            this.setItem(this.items, 'type_id', 'disabled', model != null && model.id > 0);
            this.amount = 0;
            if (model == null || (model != null && model.id == null)) {
                this.validStudent = false;
                this.student.document = this.document;
                this.student.document_type_id = 1;

                if (model != null) {
                    let type = Util.getTypeValueById(model.type_id);
                    if (type.code == 'A') {
                        types = [1, 2, 3];
                        this.setItem(this.items, 'type_id', 'disabled', true);
                    }
                }
            } else {
                this.validStudent = true;

                let type = Util.getTypeValueById(model.type_id);
                if (type.code == 'A') {
                    types = [1, 2, 3];
                }

                if (model.enrolled == true) {
                    this.formSent = true;
                    this.e1 = 3;
                }
            }
            this.setItem(this.items, 'type_id', 'filterVal', types);
        }
    }

    async getAmount() {
        let model = await this.$store.dispatch({
            type: 'fee/getByType',
            data: {
                student_id: this.student.id,
                course_id: this.courseclass.course_id
            }
        });
        this.amount = model;

    }
    
    async saveStudent() {
        if ((this.$refs.cusform as any).validate()) {

            let model = await this.$store.dispatch({
                type: 'student/' + (this.student.id > 0 ? 'update' : 'create'),
                data: this.student
            });
            if (this.student.id == null)
                this.student = Util.extend(true, {}, model);
            this.validStudent = true;
            this.studentSaved = true;
        }
    }
    
    onFileChange(file) {
        if (file != null) {
            
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.document_data = (e as any).target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async saveEnroll() {
        if ((this.$refs.vform as any).validate()) {
            await this.$store.dispatch({
                type: 'enrollment/create',
                data: {
                    student_id: this.student.id,
                    course_id: this.courseclass.course_id,
                    course_class_id: this.courseclass.id,
                    amount: this.amount,
                    notes: this.notes,
                    document_number: this.document_nro,
                    document_data: this.document_data
                }
            });

            this.formSent = true;
            this.$swal(this.L('Matricula Registrada'), this.L('Hemos registrado tu matricula, te informaremos cuando sea confirmada!'), 'success');
        }
    }

    /***** FUNCIONES DE INTERFACE *****/
    reset() {
        this.student = Object.assign({});
    }

    getInitials(string) {
        return Util.getInitials(string);
    }


    /***** INIT EVENTS *****/
    async loadClases() {
        await this.$store.dispatch({
            type: 'courseclass/getAvailable'
        });
    }

    async mounted() {
        this.validStudent = false;
        this.studentSaved = false;
        this.formSent = false;
        await this.loadClases();
    }

    created() {
        this.$store.commit('enrollment/new');
    }
    
}
